.animation {
  max-width: 30%;
  max-height: 30%;
  animation: pulse 1s linear infinite;
}

.two {
  width: 110px;
  height: 100px;
  animation: spin-two 8s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
    transform: scale(1);
  }
}
