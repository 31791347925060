@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Varela+Round&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/css/simple-line-icons.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  header,
  footer {
    background-image: url(../images/orange-bg.png);
    @apply bg-cover bg-no-repeat;
  }
  h1,
  h2,
  h3 {
    @apply font-display;
  }
  .input__field:focus ~ .input__label,
  .input__field:not(:placeholder-shown) ~ .input__label {
    @apply transition duration-200 text-xs translate-y-1;
  }
}

@layer utilities {
  .text-shadow {
    text-shadow: 1px 1px 1px orange;
  }
  .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    background-repeat: no-repeat;
  }
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

@layer components {
  input[type="range"] {
    @apply appearance-none rounded-lg bg-ct-lightgrey h-2 bg-gradient-to-r from-ct-orange to-ct-orange bg-no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none h-4 w-4 bg-ct-orange rounded-full drop-shadow;
  }
}
